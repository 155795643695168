// AdminSpotlightPage.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";

const AdminTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  background-color: #f2f2f2;
  padding: 12px;
  text-align: left;
`;

const TableCell = styled.td`
  padding: 12px;
  border-bottom: 1px solid #ddd;
`;

const ApproveButton = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 8px 12px;
  border: none;
  cursor: pointer;
`;

const AdminSpotlightPage = () => {
  const [applications, setApplications] = useState([]);
  const API_URL = "https://api.channelmatching.com";
  useEffect(() => {
    fetchApplications();
  }, []);

  const fetchApplications = async () => {
    try {
      // 정확한 API 엔드포인트 지정
      const response = await axios.get(
        `${API_URL}/api/spotlight/applications`,
        {
          withCredentials: true, // 인증 정보 포함
          headers: {
            Accept: "application/json",
          },
        }
      );
      console.log(response.data);
      setApplications(response.data);
    } catch (error) {
      console.error("Error fetching applications:", error);
    }
  };

  const handleApprove = async (app) => {
    try {
      await axios.put(
        `${API_URL}/api/spotlight/approve`,
        { app },
        {
          withCredentials: true, // 인증 정보 포함
          headers: {
            Accept: "application/json",
          },
        }
      );
      fetchApplications(); // 목록 새로고침
    } catch (error) {
      console.error("Error approving application:", error);
    }
  };

  return (
    <div>
      <h2>스포트라이트 신청 관리</h2>
      <AdminTable>
        <thead>
          <tr>
            <TableHeader>사용자 ID</TableHeader>
            <TableHeader>신청일</TableHeader>
            <TableHeader>상태</TableHeader>
            <TableHeader>액션</TableHeader>
          </tr>
        </thead>
        <tbody>
          {applications.map((app) => (
            <tr key={app._id}>
              <TableCell>{app.user}</TableCell>
              <TableCell>
                {new Date(app.appliedAt).toLocaleDateString()}
              </TableCell>
              <TableCell>{app.status}</TableCell>
              <TableCell>
                {app.status === "pending" && (
                  <ApproveButton onClick={() => handleApprove(app)}>
                    승인
                  </ApproveButton>
                )}
              </TableCell>
            </tr>
          ))}
        </tbody>
      </AdminTable>
    </div>
  );
};

export default AdminSpotlightPage;
