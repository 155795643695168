import React from "react";
import styled from "styled-components";

const Container = styled.div`
  font-family: "Poppins", sans-serif;
  margin: 20px;
  background-color: #f9f9f9;
`;

const Title = styled.h1`
  color: #000;
`;

const SectionTitle = styled.h2`
  color: #000;
`;

const Paragraph = styled.p`
  margin-bottom: 10px;
`;

const List = styled.ul`
  margin-left: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 5px;
`;

const TermsOfService = () => {
  return (
    <Container>
      <Title>채널매칭 서비스 이용약관</Title>

      <SectionTitle>제1조 (목적)</SectionTitle>
      <Paragraph>
        본 약관은 채널매칭(이하 "회사")이 제공하는 유튜브 구독채널 매칭
        서비스(이하 "서비스")의 이용조건 및 절차, 회사와 회원 간의 권리, 의무 및
        책임사항을 규정함을 목적으로 합니다.
      </Paragraph>

      <SectionTitle>제2조 (정의)</SectionTitle>
      <List>
        <ListItem>
          "서비스"란 회사가 제공하는 유튜브 구독채널 매칭 서비스로, 회원들의
          유튜브 구독 리스트를 분석하여 공통 관심사를 찾아주는 것을 의미합니다.
        </ListItem>
        <ListItem>
          "회원"이란 본 약관에 동의하고 회사와 서비스 이용계약을 체결한 자를
          말합니다.
        </ListItem>
        <ListItem>
          "유튜브 API"란 회사가 서비스 제공을 위해 사용하는 유튜브에서 제공하는
          응용 프로그램 인터페이스를 의미합니다.
        </ListItem>
      </List>

      <SectionTitle>제3조 (약관의 효력 및 변경)</SectionTitle>
      <List>
        <ListItem>
          회사는 본 약관의 내용을 회원이 쉽게 알 수 있도록 서비스 초기 화면에
          게시합니다.
        </ListItem>
        <ListItem>
          회사는 필요한 경우 약관을 변경할 수 있으며, 변경된 약관은 공지사항을
          통해 공시합니다.
        </ListItem>
      </List>

      <SectionTitle>제4조 (이용계약의 체결)</SectionTitle>
      <List>
        <ListItem>
          이용계약은 회원이 약관의 내용에 대하여 동의를 한 다음 회원가입 신청을
          하고 회사가 이러한 신청에 대하여 승낙함으로써 체결됩니다.
        </ListItem>
        <ListItem>
          회원은 서비스 이용을 위해 자신의 유튜브 계정 접근 권한을 회사에
          제공하는 것에 동의해야 합니다.
        </ListItem>
      </List>

      <SectionTitle>제5조 (서비스의 제공 및 변경)</SectionTitle>
      <Paragraph>1. 회사는 회원에게 다음과 같은 서비스를 제공합니다:</Paragraph>
      <List>
        <ListItem>유튜브 구독 채널 리스트 분석</ListItem>
        <ListItem>다른 회원과의 공통 구독 채널 매칭</ListItem>
        <ListItem>공통 관심사 기반 추천 서비스</ListItem>
      </List>
      <Paragraph>
        2. 회사는 서비스의 내용을 변경할 수 있으며, 이 경우 변경된 서비스의
        내용과 제공일자를 명시하여 현재의 서비스 내용을 게시한 곳에 공지합니다.
      </Paragraph>

      <SectionTitle>제6조 (회원의 의무)</SectionTitle>
      <Paragraph>1. 회원은 다음 행위를 하여서는 안 됩니다:</Paragraph>
      <List>
        <ListItem>신청 또는 변경 시 허위내용의 등록</ListItem>
        <ListItem>타인의 정보도용</ListItem>
        <ListItem>회사가 게시한 정보의 변경</ListItem>
        <ListItem>
          서비스를 이용하여 법령과 본 약관이 금지하거나 공서양속에 반하는 행위를
          하는 경우
        </ListItem>
        <ListItem>유튜브 API 서비스 약관을 위반하는 행위</ListItem>
      </List>

      <SectionTitle>제7조 (개인정보보호)</SectionTitle>
      <List>
        <ListItem>
          회사는 관련법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해
          노력합니다.
        </ListItem>
        <ListItem>
          회사는 유튜브 API를 통해 얻은 회원의 구독 정보를 서비스 제공
          목적으로만 사용하며, 회원의 동의 없이 제3자에게 제공하지 않습니다.
        </ListItem>
      </List>

      <SectionTitle>제8조 (서비스 제한 및 책임)</SectionTitle>
      <List>
        <ListItem>
          회사는 유튜브 API의 정책 변경 또는 기술적 문제로 인해 서비스 제공에
          제한이 있을 수 있음을 회원에게 고지합니다.
        </ListItem>
        <ListItem>
          회사는 회원 간의 매칭 결과에 대해 보증하지 않으며, 이로 인해 발생하는
          문제에 대해 책임지지 않습니다.
        </ListItem>
      </List>

      <SectionTitle>제9조 (분쟁해결)</SectionTitle>
      <List>
        <ListItem>
          회사와 회원 간에 발생한 서비스 이용에 관한 분쟁은 원만한 합의를 통해
          해결함을 원칙으로 합니다.
        </ListItem>
        <ListItem>
          합의가 이루어지지 않을 경우, 관련 법령에 따라 처리합니다.
        </ListItem>
      </List>
    </Container>
  );
};
const PrivacyPolicy = () => {
  return (
    <Container>
      <Title>개인정보처리방침</Title>

      <SectionTitle>제1조 (개인정보의 처리목적)</SectionTitle>
      <Paragraph>회사는 다음의 목적을 위하여 개인정보를 처리합니다:</Paragraph>
      <List>
        <ListItem>서비스 제공 및 계정 관리</ListItem>
        <ListItem>Google 계정을 통한 로그인 서비스 제공</ListItem>
        <ListItem>YouTube 구독 채널 매칭 서비스 제공</ListItem>
        <ListItem>서비스 이용 기록 분석 및 통계</ListItem>
      </List>

      <SectionTitle>제2조 (Google 사용자 데이터 접근 및 수집)</SectionTitle>
      <Paragraph>
        회사는 다음과 같은 Google 사용자 데이터에 접근합니다:
      </Paragraph>
      <List>
        <ListItem>
          YouTube 데이터 API를 통한 사용자의 구독 채널 목록 및 관련 메타데이터
        </ListItem>
        <ListItem>
          Google 계정 기본 프로필 정보 (이메일 주소, 프로필 이름)
        </ListItem>
        <ListItem>
          서비스 이용 과정에서 자동으로 생성되는 기록 (접속 시간, 이용 기록)
        </ListItem>
      </List>

      <SectionTitle>제3조 (Google 사용자 데이터 사용)</SectionTitle>
      <Paragraph>
        수집된 Google 사용자 데이터는 다음과 같이 사용됩니다:
      </Paragraph>
      <List>
        <ListItem>
          YouTube 구독 채널 분석을 통한 사용자 간 매칭 서비스 제공
        </ListItem>
        <ListItem>서비스 품질 개선 및 신규 서비스 개발</ListItem>
        <ListItem>사용자 인증 및 계정 관리</ListItem>
        <ListItem>서비스 이용 통계 분석</ListItem>
      </List>
      <Paragraph>
        회사는 수집된 Google 사용자 데이터를 다음과 같은 방식으로 처리합니다:
      </Paragraph>
      <List>
        <ListItem>
          YouTube 구독 목록 데이터는 수집 시점으로부터 30일간 보관됩니다
        </ListItem>
        <ListItem>
          사용자 간 매칭 데이터는 생성 시점으로부터 30일간 보관됩니다
        </ListItem>
        <ListItem>보관 기간이 만료된 데이터는 자동으로 삭제됩니다</ListItem>
      </List>

      <SectionTitle>
        제4조 (Google 사용자 데이터 공유 및 제3자 제공)
      </SectionTitle>
      <Paragraph>
        회사는 사용자의 Google 데이터를 다음과 같이 처리합니다:
      </Paragraph>
      <List>
        <ListItem>
          원칙적으로 사용자의 개인정보를 외부에 제공하지 않습니다
        </ListItem>
        <ListItem>
          서비스 제공을 위해 필요한 경우, 사용자의 명시적 동의를 받은 후에만
          제3자와 정보를 공유합니다
        </ListItem>
        <ListItem>
          초대 링크를 통해 초대받은 사용자에게는 서비스 기능 제공을 위해 초대한
          사용자의 YouTube 구독 채널 목록 중 매칭된 정보만을 제한적으로
          공개합니다
        </ListItem>
        <ListItem>
          법령에 의해 요구되는 경우에만 관련 기관에 제공될 수 있습니다
        </ListItem>
      </List>

      <SectionTitle>제5조 (데이터 보안)</SectionTitle>
      <Paragraph>
        회사는 Google 사용자 데이터 보호를 위해 다음과 같은 보안 조치를
        실시합니다:
      </Paragraph>
      <List>
        <ListItem>데이터 암호화 전송 및 저장</ListItem>
        <ListItem>접근 권한 제한 및 관리</ListItem>
        <ListItem>정기적인 보안 점검 실시</ListItem>
      </List>

      <SectionTitle>제6조 (데이터 보유 및 파기)</SectionTitle>
      <Paragraph>
        Google 사용자 데이터는 다음과 같은 보유 기간을 적용합니다:
      </Paragraph>
      <List>
        <ListItem>YouTube 구독 목록: 수집일로부터 30일</ListItem>
        <ListItem>사용자 매칭 데이터: 생성일로부터 30일</ListItem>
      </List>
      <Paragraph>
        위 보유 기간이 경과하거나 사용자가 동의를 철회하거나 삭제를 요청하는
        경우 해당 데이터는 즉시 파기됩니다.
      </Paragraph>

      <SectionTitle>제7조 (사용자 권리)</SectionTitle>
      <List>
        <ListItem>개인정보 열람, 정정, 삭제 요청 권리</ListItem>
        <ListItem>개인정보 처리 정지 요청 권리</ListItem>
        <ListItem>동의 철회 권리</ListItem>
      </List>

      <SectionTitle>제8조 (개인정보 보호책임자)</SectionTitle>
      <List>
        <ListItem>이름: 이동규</ListItem>
        <ListItem>연락처: zzrhy11@gmail.com</ListItem>
      </List>

      <SectionTitle>제9조 (개정 이력)</SectionTitle>
      <Paragraph>
        본 개인정보처리방침은 2024년 12월 8일부터 적용됩니다.
      </Paragraph>
    </Container>
  );
};

export { TermsOfService, PrivacyPolicy };
