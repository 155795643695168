// components/SpotlightIntro.js
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";

const SpotlightIntro = () => {
  const [isApplying, setIsApplying] = useState(false);
  const [message, setMessage] = useState("");
  const [hasApplied, setHasApplied] = useState(false);

  useEffect(() => {
    checkApplicationStatus();
  }, []);

  const checkApplicationStatus = async () => {
    try {
      const response = await axios.get(
        "https://api.channelmatching.com/api/spotlight/status",
        { withCredentials: true }
      );
      setHasApplied(response.data.hasApplied);
    } catch (error) {
      console.error("신청 상태 확인 중 오류 발생:", error);
    }
  };

  const handleApply = async () => {
    setIsApplying(true);
    try {
      const response = await axios.post(
        "https://api.channelmatching.com/api/spotlight/apply",
        {},
        { withCredentials: true }
      );
      setMessage(response.data.message);
      setHasApplied(true);
    } catch (error) {
      setMessage(error.response.data.message || "신청 중 오류가 발생했습니다.");
    } finally {
      setIsApplying(false);
    }
  };

  return (
    <Container>
      <Title>스포트라이트 서비스</Title>
      <Description>
        스포트라이트 서비스는 여러분의 유튜브 구독 채널을 다른 모든 사용자와
        매칭할 수 있는 특별한 기회를 제공합니다. 스포트라이트 롤을 받으면
        사이트의 모든 사용자와 채널 매칭을 할 수 있으며, 여러분의 관심사를
        공유하는 새로운 친구들을 만날 수 있습니다.
      </Description>
      <FeatureList>
        <FeatureItem>모든 사용자와 채널 매칭 가능</FeatureItem>
        <FeatureItem>매칭율과 공통 채널 수 확인</FeatureItem>
        <FeatureItem>전체 매칭 수와 순위 확인</FeatureItem>
        <FeatureItem>개인 유튜브 채널 홍보 기회</FeatureItem>
      </FeatureList>
      {!hasApplied ? (
        <ApplyButton onClick={handleApply} disabled={isApplying}>
          {isApplying ? "신청 중..." : "스포트라이트 신청하기"}
        </ApplyButton>
      ) : (
        <AppliedMessage>이미 스포트라이트를 신청하셨습니다.</AppliedMessage>
      )}
      {message && <Message>{message}</Message>}
      <Link to="/spotlight/users">
        <ViewUsersButton>스포트라이트 유저 보기</ViewUsersButton>
      </Link>
    </Container>
  );
};

export default SpotlightIntro;

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
`;

const Description = styled.p`
  font-size: 1.1rem;
  color: #666;
  line-height: 1.6;
  margin-bottom: 30px;
`;

const FeatureList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-bottom: 30px;
`;

const FeatureItem = styled.li`
  font-size: 1rem;
  color: #444;
  margin-bottom: 10px;
  &:before {
    content: "✓";
    color: #4caf50;
    margin-right: 10px;
  }
`;

const ApplyButton = styled.button`
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 1.1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #45a049;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const AppliedMessage = styled.p`
  font-size: 1.1rem;
  color: #4caf50;
  font-weight: bold;
`;

const Message = styled.p`
  margin-top: 20px;
  font-size: 1rem;
  color: ${(props) => (props.error ? "#f44336" : "#4caf50")};
`;

const ViewUsersButton = styled.button`
  background-color: #3f51b5;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 1.1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-top: 20px;

  &:hover {
    background-color: #303f9f;
  }
`;
