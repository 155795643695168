// Footer.js
import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <footer className="site-footer">
      <div className="site-footer-info">
        <div className="site-footer-title">회사 정보</div>
        <div className="site-footer-text">채널매칭 (Channel Matching)</div>
        <div className="site-footer-text">사업자등록번호: 186-26-01453</div>
        <div className="site-footer-text">대표: 이동규</div>
        {/* <div className="site-footer-text">
          주소: 부산광역시 기장군 정관읍 정관2로
        </div> */}
        <div className="site-footer-text">이메일: zzrhy11@gmail.com</div>
        <div className="site-footer-links">
          <div>
            <a
              className="site-footer-link"
              href="/terms/TermsOfService"
              onClick={(e) => {
                e.preventDefault();
                window.location.href = "/TermsOfService";
              }}
            >
              이용약관
            </a>
          </div>
          <div>
            <a
              className="site-footer-link"
              href="/terms/PrivacyPolicy"
              onClick={(e) => {
                e.preventDefault();
                window.location.href = "/PrivacyPolicy";
              }}
            >
              개인정보처리방침
            </a>
          </div>
        </div>
        <div className="site-footer-text">
          © 2024 채널매칭. All rights reserved.
        </div>
      </div>
    </footer>
  );
}

export default Footer;
