import React from "react";
import { Box, Button, Snackbar, Typography, Paper } from "@mui/material";
import { FaLink } from "react-icons/fa6";
import styled from "styled-components";

const ShareContainer = styled(Paper)`
  text-align: center;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
`;

function ShareLink({ shareLink, user, open, setOpen }) {
  const handleCopy = () => {
    navigator.clipboard.writeText(shareLink);
    setOpen(true);
  };
  if (!user) {
    return null;
  }
  return (
    <ShareContainer elevation={0}>
      <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
        <Typography variant="h6" fontWeight="500">
          친구와 함께 유튜브 채널 취향을 공유해보세요!
        </Typography>

        <Button
          variant="contained"
          color="primary"
          onClick={handleCopy}
          startIcon={<FaLink />}
          sx={{
            borderRadius: "50px",
            fontSize: "1rem",
          }}
        >
          초대 링크 복사하기
        </Button>
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message={`초대링크가 복사되었습니다!`}
      />
    </ShareContainer>
  );
}

export default ShareLink;
