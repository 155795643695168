// components/SpotlightUsers.js
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import SpotlightUserTab from "./SpotlightUserTab";

const SpotlightUsers = () => {
  const [spotlightUsers, setSpotlightUsers] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    fetchSpotlightUsers();
  }, []);

  const fetchSpotlightUsers = async () => {
    try {
      const response = await axios.get(
        "https://api.channelmatching.com/api/spotlight/users",
        { withCredentials: true }
      );
      setSpotlightUsers(response.data);
    } catch (error) {
      console.error("스포트라이트 유저 정보를 가져오는 중 오류 발생:", error);
    }
  };

  return (
    <Container>
      <TabList>
        {spotlightUsers.map((user, index) => (
          <TabItem
            key={user.uuid}
            active={activeTab === index}
            onClick={() => setActiveTab(index)}
          >
            {user.displayName}
          </TabItem>
        ))}
      </TabList>
      {spotlightUsers[activeTab] && (
        <SpotlightUserTab user={spotlightUsers[activeTab]} />
      )}
    </Container>
  );
};

export default SpotlightUsers;

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const TabList = styled.div`
  display: flex;
  overflow-x: auto;
  margin-bottom: 20px;
`;

const TabItem = styled.button`
  padding: 10px 20px;
  border: none;
  background-color: ${(props) => (props.active ? "#4caf50" : "#f1f1f1")};
  color: ${(props) => (props.active ? "white" : "black")};
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${(props) => (props.active ? "#45a049" : "#ddd")};
  }
`;
