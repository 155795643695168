import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import UserInfo from "./UserInfo";
import TabNavigation from "./TabNavigation";
import SubscriptionList from "./SubscriptionList";
import CommonSubscriptions from "./CommonSubscriptions";
import ComparisonModal from "./ComparisonModal";
import CircularProgress from "@mui/material/CircularProgress";
import ShareLink from "./ShareLink";

const API_URL = "https://api.channelmatching.com";

function Dashboard() {
  const [user, setUser] = useState(null);
  const [subscriptions, setSubscriptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [shareLink, setShareLink] = useState("");
  const [open, setOpen] = useState(false);
  const [compareUsers, setCompareUsers] = useState([]);
  const [activeTab, setActiveTab] = useState("mySubscriptions");
  const [commonSubscriptions, setCommonSubscriptions] = useState([]);
  const [compareUser, setCompareUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isComparing, setIsComparing] = useState(false);
  const [showInviteButton, setShowInviteButton] = useState(false);
  const [inviteUserName, setInviteUserName] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [userRes, subscriptionsRes, commonSubscriptionsRes] =
          await Promise.all([
            axios.get(`${API_URL}/auth/user`, { withCredentials: true }),
            axios.get(`${API_URL}/youtube/subscriptions`, {
              withCredentials: true,
            }),
            axios.get(`${API_URL}/match/find-common-subscriptions`, {
              withCredentials: true,
            }),
          ]);

        const userData = userRes.data;
        setUser(userData);
        setSubscriptions(subscriptionsRes.data.subscriptions || []);
        setShareLink(`https://channelmatching.com/?compare=${userData.uuid}`);
        setCommonSubscriptions(
          commonSubscriptionsRes.data.commonSubscriptions || []
        );

        const comparedUsers =
          commonSubscriptionsRes.data.commonSubscriptions.map((cs) => ({
            ...cs.users.find((u) => u.uuid !== userData.uuid),
            matchingRate: cs.matchingRate,
            commonCount: cs.commonCount,
            aUserRecentSubsLength: cs.aUserRecentSubsLength,
            myRecentSubsLength: cs.myRecentSubsLength,
            totalUniqueCount: cs.totalUniqueCount,
          }));
        setCompareUsers(comparedUsers);

        // 두 번째 useEffect의 로직을 여기로 이동
        const compareUUID = localStorage.getItem("compare");
        if (compareUUID && compareUUID !== userData.uuid) {
          try {
            const response = await axios.get(
              `${API_URL}/auth/user/${compareUUID}`,
              {
                withCredentials: true,
              }
            );
            setCompareUser(response.data);
            setShowModal(true);
          } catch (error) {
            console.error(
              "비교 대상 사용자 정보를 가져오는데 실패했습니다:",
              error
            );
          }
        } else if (compareUUID === userData.uuid) {
          localStorage.removeItem("compare");
        }
      } catch (err) {
        console.error("데이터를 가져오는데 실패했습니다:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSaveAndFetchCommonSubscriptions = async () => {
    try {
      const authResponse = await axios.get(`${API_URL}/auth/check`, {
        withCredentials: true,
      });

      if (!authResponse.data.isAuthenticated) {
        console.log("로그인이 필요한 서비스입니다.");
        window.location.href = "/login";
        return;
      }

      const compareUUID = localStorage.getItem("compare");
      if (!compareUUID) {
        console.log("비교할 사용자의 정보가 없습니다.");
        return;
      }

      setIsComparing(true);
      try {
        const saveResponse = await axios.post(
          `${API_URL}/match/save-common-subscriptions`,
          { aUserUUID: compareUUID },
          { withCredentials: true }
        );

        if (saveResponse.data.status === 400) {
          console.log(saveResponse.data.message);
          if (saveResponse.data.details) {
            const details = saveResponse.data.details;
            const userWithoutSubscriptions = Object.keys(details).find(
              (key) => details[key] === "구독 목록 없음"
            );
            if (userWithoutSubscriptions) {
              setInviteUserName(userWithoutSubscriptions);
              setShowInviteButton(true);
            }
          }
          return;
        }

        const response = await axios.get(
          `${API_URL}/match/find-common-subscriptions`,
          { withCredentials: true }
        );

        setCommonSubscriptions(response.data.commonSubscriptions || []);
        const comparedUsers = response.data.commonSubscriptions.map((cs) => ({
          ...cs.users.find((u) => u.uuid !== user.uuid),
          matchingRate: cs.matchingRate,
          commonCount: cs.commonCount,
          aUserRecentSubsLength: cs.aUserRecentSubsLength,
          myRecentSubsLength: cs.myRecentSubsLength,
          totalUniqueCount: cs.totalUniqueCount,
        }));
        setCompareUsers(comparedUsers);

        setShowModal(false);
        setActiveTab(`commonSubscriptions-0`);
        localStorage.removeItem("compare");
      } catch (error) {
        console.error("공통 구독 정보 저장 및 조회에 실패했습니다:", error);
      } finally {
        setIsComparing(false);
      }
    } catch (authError) {
      console.error("인증 확인 실패:", authError);
      console.log("로그인 상태를 확인할 수 없습니다.");
    }
  };

  const handleSendInvite = () => {
    // 여기에 초대 링크를 보내는 로직을 구현합니다.
    navigator.clipboard.writeText(shareLink);
    setOpen(true);
    // 초대 링크 보내기 로직 실행 후
    setShowInviteButton(false);
    setShowModal(false);
  };

  const closeModal = () => {
    setShowModal(false);
    setShowInviteButton(false);
    setInviteUserName("");
  };

  if (isLoading) {
    return (
      <DashboardContainer2>
        <CircularProgress />
      </DashboardContainer2>
    );
  }

  return (
    <DashboardContainer>
      {showModal && compareUser && (
        <ComparisonModal
          isComparing={isComparing}
          compareUser={compareUser}
          handleSaveAndFetchCommonSubscriptions={
            handleSaveAndFetchCommonSubscriptions
          }
          showInviteButton={showInviteButton}
          inviteUserName={inviteUserName}
          handleSendInvite={handleSendInvite}
          closeModal={closeModal}
        />
      )}
      <ContentSection>
        <Row>
          <UserInfo user={user} />
          <ShareLink
            user={user}
            shareLink={shareLink}
            open={open}
            setOpen={setOpen}
          />
        </Row>

        <TabSection>
          <TabNavigation
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            compareUsers={compareUsers}
            user={user}
          />
        </TabSection>
        {user && activeTab === "mySubscriptions" && (
          <SubscriptionList subscriptions={subscriptions} />
        )}
        {user && activeTab.startsWith("commonSubscriptions") && (
          <CommonSubscriptions
            compareUsers={compareUsers}
            commonSubscriptions={commonSubscriptions}
            compareUserIndex={parseInt(activeTab.split("-")[1])}
          />
        )}
      </ContentSection>
    </DashboardContainer>
  );
}

export default Dashboard;
const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 62px); // 헤더 높이만큼 제외
  padding: 20px;
`;
const DashboardContainer2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 62px); // 헤더 높이만큼 제외
  padding: 20px;
`;

const ContentSection = styled.div`
  flex: 1;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 10px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const TabSection = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;
