import React from "react";
import { NavLink, Routes, Route } from "react-router-dom";
import styled from "styled-components";
import Dashboard2 from "./Dashboard2";
import Spotlight from "./Spotlight";

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  @media (min-width: 769px) {
    flex-direction: row;
  }
`;

const Sidebar = styled.div`
  background-color: #2c3e50;
  padding: 10px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  @media (min-width: 769px) {
    width: 200px;
    height: 100%;
  }
`;

const Content = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const StyledNavLink = styled(NavLink)`
  display: block;
  padding: 12px 16px;
  color: #ecf0f1;
  text-decoration: none;
  font-size: 16px;
  border-radius: 4px;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #34495e;
  }

  &.active {
    background-color: #3498db;
    font-weight: bold;
  }
`;

const SpotlightLink = styled(StyledNavLink)`
  position: relative;
  overflow: hidden;
`;

function Layout() {
  return (
    <LayoutContainer>
      <Sidebar>
        <StyledNavLink to="/" end>
          내 채널
        </StyledNavLink>
        <SpotlightLink to="/spotlight">스포트라이트</SpotlightLink>
      </Sidebar>
      <Content>
        <Routes>
          <Route path="/" element={<Dashboard2 />} />
          <Route path="/spotlight/*" element={<Spotlight />} />
        </Routes>
      </Content>
    </LayoutContainer>
  );
}

export default Layout;
