import React from "react";
import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";

const ComparisonModal = ({
  isComparing,
  compareUser,
  handleSaveAndFetchCommonSubscriptions,
  showInviteButton,
  inviteUserName,
  handleSendInvite,
  closeModal,
}) => {
  return (
    <Modal onClick={closeModal}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        {isComparing ? (
          <LoadingWrapper>
            <CircularProgress />
            <p>채널 목록을 조회/비교하고 있습니다...</p>
          </LoadingWrapper>
        ) : showInviteButton ? (
          <>
            <h2>{inviteUserName}님의 구독 정보가 필요해요!</h2>
            <p>
              {inviteUserName}님이 구독 채널 정보를 갱신하면 더 많은 공통
              관심사를 발견할 수 있어요. 초대 링크를 공유해 주시겠어요?
            </p>
            <p className="sub-text">
              (함께 유튜브 취향을 공유하면 더 재미있을 거예요!)
            </p>
            <ModalButton
              onClick={() => {
                handleSendInvite();
                closeModal();
              }}
            >
              {inviteUserName}님께 보낼 초대장 복사
            </ModalButton>
          </>
        ) : (
          <>
            <h2>{compareUser.displayName}님과의 공통 구독</h2>
            <p>
              지금 바로 {compareUser.displayName}님과 공통으로 구독중인 채널을
              찾아보세요!
            </p>
            <ModalButton onClick={handleSaveAndFetchCommonSubscriptions}>
              공통 구독 채널 찾기
            </ModalButton>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ComparisonModal;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
  width: 90%;
`;

const ModalButton = styled.button`
  background-color: #4285f4;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 1rem;

  &:hover {
    background-color: #3367d6;
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
