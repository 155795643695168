import React from "react";
import { Avatar, Typography, Box } from "@mui/material";

function UserInfo({ user }) {
  if (!user) {
    return null;
  }

  return (
    <Box display="flex" alignItems="center" mb={2}>
      <Avatar
        src={user?.image || ""} // null 체크 추가
        alt={user?.displayName || "User"}
        sx={{ width: 60, height: 60, mr: 2 }}
      />
      <Box>
        <Typography variant="h5">{user?.displayName || "사용자"}</Typography>
        <Typography variant="body2" color="textSecondary">
          {user?.email || ""}
        </Typography>
      </Box>
    </Box>
  );
}

export default UserInfo;
