import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import axios from "axios";

const SpotlightUserTab = ({ user }) => {
  const [matchingResult, setMatchingResult] = useState(null);
  const [matchingStats, setMatchingStats] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchMatchingStats = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `https://api.channelmatching.com/api/spotlight/stats/${user.uuid}`,
        { withCredentials: true }
      );
      setMatchingStats(response.data);
    } catch (error) {
      console.error("매칭 통계를 가져오는 중 오류 발생:", error);
      setError("매칭 통계를 불러오는데 실패했습니다.");
    } finally {
      setLoading(false);
    }
  }, [user.uuid]);

  useEffect(() => {
    fetchMatchingStats();
    setMatchingResult(null); // 유저가 변경될 때마다 매칭 결과 초기화
  }, [fetchMatchingStats, user.uuid]);
  const handleMatchingRequest = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(
        `https://api.channelmatching.com/api/spotlight/match/${user.uuid}`,
        {},
        { withCredentials: true }
      );
      setMatchingResult(response.data);
    } catch (error) {
      console.error("매칭 요청 중 오류 발생:", error);

      // 서버에서 반환된 오류 메시지 처리
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setError(error.response.data.message); // 서버의 메시지를 에러로 설정
      } else {
        setError("매칭 요청에 실패했습니다."); // 일반적인 에러 메시지
      }
    } finally {
      setLoading(false);
    }
  };

  if (!user) {
    return <Container>사용자 정보를 찾을 수 없습니다.</Container>;
  }

  return (
    <Container>
      <UserInfo>
        <Avatar src={user.image} alt={user.displayName} />
        <Name>{user.displayName}</Name>
        <Email>{user.email}</Email>
        <SpotlightDate>
          스포트라이트 승인일: {new Date(user.approvedAt).toLocaleDateString()}
        </SpotlightDate>
      </UserInfo>

      {error && <ErrorMessage>{error}</ErrorMessage>}

      {loading ? (
        <LoadingMessage>데이터를 불러오는 중...</LoadingMessage>
      ) : (
        <>
          {!matchingResult && (
            <MatchButton onClick={handleMatchingRequest} disabled={loading}>
              매칭 신청
            </MatchButton>
          )}

          {matchingResult && (
            <MatchingResult>
              <h3>매칭 결과</h3>
              <p>매칭율: {matchingResult.matchingRate}%</p>
              <p>공통 채널 수: {matchingResult.commonChannels}</p>
            </MatchingResult>
          )}

          {matchingStats && (
            <MatchingStats>
              <h3>매칭 통계</h3>
              <p>총 매칭 인원: {matchingStats.totalMatches}</p>
              <p>최고 매칭율: {matchingStats.highestMatchingRate}%</p>
              <p>최다 공통 채널: {matchingStats.highestCommonChannels}</p>
              <p>매칭 순위: {matchingStats.rank}</p>
            </MatchingStats>
          )}
        </>
      )}
    </Container>
  );
};

// PropTypes 및 styled-components 코드는 이전과 동일

SpotlightUserTab.propTypes = {
  user: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    image: PropTypes.string,
    approvedAt: PropTypes.string.isRequired,
  }).isRequired,
};

export default SpotlightUserTab;

const Container = styled.div`
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
`;

const UserInfo = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const Avatar = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
`;

const Name = styled.h2`
  margin: 0;
  color: #333;
`;

const Email = styled.p`
  color: #666;
  margin: 5px 0;
`;

const SpotlightDate = styled.p`
  color: #888;
  font-size: 0.9em;
`;

const MatchButton = styled.button`
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #45a049;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const MatchingResult = styled.div`
  margin-top: 20px;
  background-color: #e8f5e9;
  padding: 15px;
  border-radius: 5px;
`;

const MatchingStats = styled.div`
  margin-top: 20px;
  background-color: #e3f2fd;
  padding: 15px;
  border-radius: 5px;
`;

const ErrorMessage = styled.div`
  color: #f44336;
  padding: 10px;
  margin: 10px 0;
  background-color: #ffebee;
  border-radius: 4px;
`;

const LoadingMessage = styled.div`
  text-align: center;
  color: #666;
  padding: 20px;
`;
