import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Login from "./components/Login";
import CircularProgress from "@mui/material/CircularProgress";
import Layout from "./components/Layout";
import Landing from "./components/Landing";
import axios from "axios";
import Footer from "./components/Footer";
import Logo from "./components/Logo";
import User from "./components/User";
import Admin from "./admin/Admin";
import { PrivacyPolicy, TermsOfService } from "./components/Terms";

const API_URL = "https://api.channelmatching.com";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  const [user, setUser] = useState({ name: "", image: "" });
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);

    const compareParam = urlParams.get("compare");
    if (compareParam) {
      localStorage.setItem("compare", compareParam);
    }

    const checkAuth = async () => {
      try {
        const response = await axios.get(`${API_URL}/auth/check`, {
          withCredentials: true,
        });

        // 상태 업데이트를 한 번에 처리
        if (response.data.isAuthenticated && response.data.user) {
          setIsAuthenticated(true);
          setUser({
            name: response.data.user.name || "",
            image: response.data.user.image || "",
          });
        } else {
          setIsAuthenticated(false);
          setUser({ name: "", image: "" });
        }
      } catch (error) {
        setIsAuthenticated(false);
        setUser({ name: "", image: "" });
      }
    };

    checkAuth();
  }, [location.search]);

  if (isAuthenticated === null) {
    return (
      <AppContainer>
        <CircularProgress />
      </AppContainer>
    );
  }

  return (
    <AppContainer>
      <HeaderSection>
        <Logo />
        <User isAuthenticated={isAuthenticated} user={user} />
      </HeaderSection>
      <BodySection>
        <Routes>
          <Route
            path="/*"
            element={isAuthenticated ? <Layout /> : <Landing />}
          />
          <Route
            path="/login"
            element={isAuthenticated ? <Navigate to="/" /> : <Login />}
          />
          <Route path="/TermsOfService" element={<TermsOfService />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/admin" element={<Admin />} />
        </Routes>
      </BodySection>
      <FooterSection>
        <Footer />
      </FooterSection>
    </AppContainer>
  );
}

export default App;
const AppContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;
const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  height: 60px;
  border-bottom: 2px solid #ddd;
  z-index: 1000; /* Ensure it stays above other content */
  background-color: white;
`;

const BodySection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  width: 100%;
  margin-top: 62px; // 헤더 높이만큼 여백
`;

const FooterSection = styled.div`
  margin-top: auto; // 컨텐츠가 짧을 때 하단에 고정
`;
