import React from "react";
import styled from "styled-components";

function CommonSubscriptions({
  commonSubscriptions,
  compareUserIndex,
  compareUsers,
}) {
  const currentComparison = commonSubscriptions[compareUserIndex];

  if (!currentComparison) {
    return <Container>선택된 비교 정보가 없습니다.</Container>;
  }

  const compareUser = compareUsers[compareUserIndex];
  const matchRate = currentComparison.matchingRate ?? 0;
  const commonCount = currentComparison.commonCount ?? 0;
  const totalUniqueCount = currentComparison.totalUniqueCount ?? 0;

  // 현재 사용자와 비교 대상 사용자의 정보를 찾습니다.
  const myUserInfo = currentComparison.users.find(
    (user) => user.uuid !== compareUser.uuid
  );
  const compareUserInfo = currentComparison.users.find(
    (user) => user.uuid === compareUser.uuid
  );

  const myRecentSubsLength = myUserInfo?.recentSubsLength ?? 0;
  const compareUserRecentSubsLength = compareUserInfo?.recentSubsLength ?? 0;

  return (
    <Container>
      <Title>{compareUser?.displayName}님과의 공통 구독 채널</Title>
      <MatchInfo>
        와우! {compareUser?.displayName}님과 당신은
        <strong>{matchRate.toFixed(1)}%</strong>의 취향이 일치해요! 🎉
      </MatchInfo>

      <ChannelGrid>
        {currentComparison.subscriptions.map((subscription) => (
          <ChannelItem key={subscription.channelId}>
            <ChannelImage>
              <img src={subscription.thumbnailUrl} alt={subscription.title} />
            </ChannelImage>
            <ChannelName className="channel-name">
              {subscription.title}
            </ChannelName>
          </ChannelItem>
        ))}
      </ChannelGrid>
      <AdditionalInfo>
        <InfoItem>공통 구독 채널: {commonCount}개</InfoItem>
        <InfoItem>
          {compareUser?.displayName}님의 최근 구독:{" "}
          {compareUserRecentSubsLength}개
        </InfoItem>
        <InfoItem>내 최근 구독: {myRecentSubsLength}개</InfoItem>
        <InfoItem>비교 구독 채널: {totalUniqueCount}개</InfoItem>
        <ResourceInfo>
          많은 사용자분들이 동시에 서비스를 이용하고 계셔서, 원활한 서비스
          제공을 위해 유저당 최근 구독한 채널 100개 까지만 비교가 가능합니다.
          양해 부탁드립니다.
        </ResourceInfo>
      </AdditionalInfo>
    </Container>
  );
}

export default CommonSubscriptions;

const Container = styled.div`
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
`;

const MatchInfo = styled.p`
  font-size: 18px;
  margin-bottom: 30px;
  color: #666;

  strong {
    color: #4285f4;
    font-size: 24px;
    margin: 0 5px;
  }
`;

const ChannelGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 20px;
  padding: 20px 0;
`;

const ChannelItem = styled.div`
  position: relative;
  cursor: pointer;

  &:hover .channel-name {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ChannelImage = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }

  &:hover img {
    transform: scale(1.1);
  }
`;

const ChannelName = styled.div`
  position: absolute;
  bottom: -20px; // 간격을 더 늘림
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 8px;
  text-align: center;
  border-radius: 4px;
  opacity: 0;
  transform: translateY(10px);
  transition: all 0.3s ease;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 1; // 다른 요소들보다 위에 표시되도록 함
`;

const AdditionalInfo = styled.div`
  margin-bottom: 20px;
  background-color: #f0f0f0;
  padding: 15px;
  border-radius: 8px;
`;

const InfoItem = styled.p`
  margin: 5px 0;
  font-size: 16px;
  color: #333;
`;

const ResourceInfo = styled.div`
  font-size: 12px;
  color: #666;
  text-align: center;
  margin: 12px 0;
  opacity: 0.8;
  background-color: #f8f9fa;
  padding: 8px 12px;
  border-radius: 6px;
`;
