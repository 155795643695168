import React from "react";
import styled from "styled-components";

const Container = styled.div`
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: 40px;
  margin: 0;
  color: #333;
`;

const SubscriptionInfo = styled.p`
  font-size: 18px;
  margin-bottom: 30px;
  color: #666;

  strong {
    color: #4285f4;
    font-size: 24px;
    margin: 0 5px;
  }
`;

const ChannelGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 20px;
  padding: 20px 0;
`;

const ChannelItem = styled.div`
  position: relative;
  cursor: pointer;

  &:hover .channel-name {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ChannelImage = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }

  &:hover img {
    transform: scale(1.1);
  }
`;

const ChannelName = styled.div`
  position: absolute;
  bottom: -20px; // 간격을 더 늘림
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 8px;
  text-align: center;
  border-radius: 4px;
  opacity: 0;
  transform: translateY(10px);
  transition: all 0.3s ease;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 1; // 다른 요소들보다 위에 표시되도록 함
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

function SubscriptionList({ subscriptions }) {
  return (
    <Container>
      <Row>
        <Title>구독 채널</Title>
      </Row>
      {subscriptions.length === 0 ? (
        <SubscriptionInfo>구독 중인 채널이 없습니다.</SubscriptionInfo>
      ) : (
        <>
          <SubscriptionInfo>
            현재 <strong>{subscriptions.length}</strong>개의 채널을 구독하고
            있어요! 🎉
          </SubscriptionInfo>
          <ChannelGrid>
            {subscriptions.map((subscription) => (
              <ChannelItem key={subscription.channelId}>
                <ChannelImage>
                  <img
                    src={subscription.thumbnailUrl}
                    alt={subscription.title}
                  />
                </ChannelImage>
                <ChannelName className="channel-name">
                  {subscription.title}
                </ChannelName>
              </ChannelItem>
            ))}
          </ChannelGrid>
        </>
      )}
    </Container>
  );
}

export default SubscriptionList;
