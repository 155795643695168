import React from "react";
import { Tabs, Tab } from "@mui/material";

function TabNavigation({ activeTab, setActiveTab, compareUsers, user }) {
  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  if (!user) {
    return null;
  }
  return (
    <Tabs
      value={activeTab}
      onChange={handleChange}
      variant="scrollable"
      scrollButtons="auto"
    >
      <Tab label="내 구독" value="mySubscriptions" />
      {compareUsers.map((user, index) => (
        <Tab
          key={user.uuid}
          label={`${user.displayName}과의 공통 구독`}
          value={`commonSubscriptions-${index}`}
        />
      ))}
    </Tabs>
  );
}

export default TabNavigation;
